import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [0,2,4,6];

export const dictionary = {
		"/": [7],
		"/(app)/_messages": [9,[2,3]],
		"/(app)/_messages/[roomId]": [10,[2,3]],
		"/(app)/ai-chat": [11,[2]],
		"/(admin)/ai": [8],
		"/(login)/create-admin": [47,[5]],
		"/(login)/create-invite": [48,[5]],
		"/(app)/dashboard": [12,[2]],
		"/(app)/events": [13,[2,4]],
		"/(login)/(user-invites)/ex/[inviteCode]": [~46,[5]],
		"/(app)/guests": [~14,[2]],
		"/(app)/investors": [15,[2]],
		"/(app)/invites": [16,[2]],
		"/(login)/invite/[inviteCode]": [~49,[5]],
		"/(login)/login": [50,[5]],
		"/(app)/my-items": [17,[2]],
		"/(app)/my-token": [18,[2]],
		"/(app)/notifications": [19,[2]],
		"/(onboarding)/onboarding/company": [54,[6]],
		"/(onboarding)/onboarding/complete": [55,[6]],
		"/(onboarding)/onboarding/investor": [56,[6]],
		"/(app)/otc": [20,[2]],
		"/(app)/otc/onboarding": [21,[2]],
		"/(app)/otc/onboarding/complete": [22,[2]],
		"/(app)/portfolio": [23,[2]],
		"/(app)/pre-token-companies": [24,[2]],
		"/(app)/profile/[userId]": [25,[2]],
		"/(app)/reporting": [26,[2]],
		"/(login)/reset-keys": [52,[5]],
		"/(login)/reset": [51,[5]],
		"/(app)/settings": [27,[2]],
		"/(app)/settings/account": [28,[2]],
		"/(app)/settings/activity-logs": [29,[2]],
		"/(app)/settings/billing": [30,[2]],
		"/(app)/settings/company": [31,[2]],
		"/(app)/settings/external-users": [~32,[2]],
		"/(app)/settings/guests": [33,[2]],
		"/(app)/settings/policies": [34,[2]],
		"/(app)/settings/security": [35,[2]],
		"/(app)/settings/users": [36,[2]],
		"/(app)/shared-data": [37,[2]],
		"/swagger-ui": [57],
		"/(app)/team": [38,[2]],
		"/(app)/token-cap-table": [39,[2]],
		"/(app)/token-plan": [40,[2]],
		"/(app)/token-plan/onboarding": [41,[2]],
		"/(app)/token-plan/onboarding/complete": [42,[2]],
		"/(app)/token-unlocks": [43,[2]],
		"/(login)/unsubscribe": [~53,[5]],
		"/(app)/vesting-admin": [~44,[2]],
		"/(app)/wallet-management": [45,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';